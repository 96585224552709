<template>
	<div class="mission row justify-content-center text-secondary pt-3 pt-lg-4" data-aos="fade-up" data-aos-duration="1500">
		<div class="col-12 col-xl-10">
			<div class="mission__main traffic px-lg-3 pt-2 pt-md-0">
				<img v-if="deviceMode != 'mobile'" class="mission__title d-block mx-auto mb-4" src="@/assets/images/mission/img_t_title.png" alt="淡水八景4.0">
				<img v-else class="mission__title traffic d-block mx-auto pl-3" src="@/assets/images/mission/img_t_title_s.png" alt="淡水八景4.0">
				
				<div class="mission__contain traffic row justify-content-center">
					<div class="col-10 col-md-12 col-lg-11">
						<!-- 1.0 -->
						<div class="row traffic__1">
							<div class="col-md-6 d-flex" data-aos="fade-up" data-aos-duration="1500">
								<div class="traffic__content pl-lg-2">
									<img class="traffic__subtitle mr-auto mx-md-auto ml-lg-auto mr-lg-4 mb-3" src="@/assets/images/mission/img_subtitle1.png" alt="">
									<div v-if="deviceMode == 'mobile'" id="boat" class="img__main w-100"></div>
									<p class="mt-3 mb-5 my-md-0">早在16世紀的<span class="text-danger font-weight-bold">大航海時代（1.0）</span>，淡水是西班牙與荷蘭人的重要海上貿易據點，水運的發展也帶動了的淡水繁華，是個舉世聞名的港口。咸豐八年（1858）簽訂的「天津條約」中開放了多個通商的口岸，其中之一就是淡水港，更促使了淡水航運的發展，不僅促進淡水內港新莊、艋舺、大稻埕街市商業的發展，淡水的綺麗風貌也就遐邇聞名，名聲遠播。</p>
								</div>
							</div>
							<div v-if="deviceMode != 'mobile'" class="col-md-6" data-aos="zoom-in" data-aos-duration="1500">
								<div id="boat" class="img__main"></div>
								<img src="@/assets/images/mission/img_t_1_deco.png" alt="" class="position-absolute img__deco">
							</div>							
						</div>

						<!-- 2.0 -->
						<div class="row traffic__2">
							<div class="col-md-6 order-md-2 d-flex" data-aos="fade-up" data-aos-duration="1500">
								<div class="traffic__content pl-lg-4">
									<img class="traffic__subtitle mr-auto mx-md-auto mr-lg-auto ml-lg-0 mb-3" src="@/assets/images/mission/img_subtitle2.png" alt="">
									<div v-if="deviceMode == 'mobile'" id="train" class="img__main w-100"></div>
									<img v-if="deviceMode == 'mobile'" src="@/assets/images/mission/img_t_2_deco.png" alt="" class="position-absolute img__deco">
									<p class="mt-3 mb-5 my-md-0">為了能夠在淡水河沿線航運的方式更快速，並大量地輸送原本經海路運至淡水港的物資，於1901年8月25日台鐵淡水線通車。隨著<span class="text-danger font-weight-bold">鐵路運輸（2.0）</span>提供了更多、更快速的便利交通，讓往來的人潮日增。更因淡水山水美景而重現了「淡北八景」的獨特景致。更間接開啟了淡水的旅遊與觀光產業。</p>
								</div>
							</div>
							<div v-if="deviceMode != 'mobile'" class="col-md-6 order-md-1 d-flex justify-content-end" data-aos="zoom-in" data-aos-duration="1500">
								<div id="train" class="img__main align-self-end"></div>
								<img src="@/assets/images/mission/img_t_2_deco.png" alt="" class="position-absolute img__deco">
							</div>
						</div>

						<!-- 3.0 -->
						<div class="row traffic__3">
							<div class="col-md-6 d-flex mt-0 mt-md-4 mt-lg-0" data-aos="fade-up" data-aos-duration="1500">
								<div class="traffic__content">
									<img class="traffic__subtitle mr-auto mx-md-auto ml-lg-auto mb-3 mr-lg-3" src="@/assets/images/mission/img_subtitle3.png" alt="捷運3.0">
									<div v-if="deviceMode == 'mobile'" id="mrt" class="img__main w-100"></div>
									<img v-if="deviceMode == 'mobile'" src="@/assets/images/mission/img_t_3_deco.png" alt="" class="position-absolute img__deco">
									<p class="mt-3 mb-5 my-md-0">台鐵淡水支線原為資材的運輸而築的鐵道，為了節約工程費用，多數使用清代舊線的廢物拼裝組合。在「台北都會區大眾捷運系統計畫」中，將淡水線升級為捷運路線，成為台灣第一條由傳統鐵路改建為<span class="text-danger font-weight-bold">高運量捷運（3.0）</span>的路線。淡水支線於1988年停止營運，捷運淡水線於1997通車，成為台灣最早通車的高運量捷運路段。捷運自1997年通車至今已有60億以上的高乘載量，帶動了該地區（淡水）的國內外旅客，與國內的新住民人口的觀光人潮，更將淡水的美景推向了另一個高峰。</p>
								</div>
							</div>
							<div v-if="deviceMode != 'mobile'" class="col-md-6 d-flex" data-aos="zoom-in" data-aos-duration="1500">
								<div id="mrt" class="img__main align-self-center"></div>
								<img src="@/assets/images/mission/img_t_3_deco.png" alt="" class="position-absolute img__deco">
							</div>
						</div>

						<!-- 4.0 -->
						<div class="row traffic__4">
							<div class="col-md-6 order-md-2 d-flex mt-0 mt-md-4 mt-lg-0" data-aos="fade-up" data-aos-duration="1500">
								<div class="traffic__content pl-lg-4">
									<img class="traffic__subtitle mr-auto mx-md-auto mr-lg-auto ml-lg-0 mb-3" src="@/assets/images/mission/img_subtitle4.png" alt="">
									<div v-if="deviceMode == 'mobile'" id="lrt" class="img__main w-100"></div>
									<p class="mt-3 mb-5 my-md-0">在捷運通車後的推波助瀾下，接駁捷運的多元公車與自行車也日漸興盛，淡水觀光人潮與日劇增。<span class="text-danger font-weight-bold">淡海輕軌路網（4.0）</span>的完工，更串起了淡水的青山、海河、歷史古蹟、絕色美景與生活型態的交通網絡，展現古鎮與現代交融的全新風貌。</p>
								</div>
							</div>
							<div v-if="deviceMode != 'mobile'" class="col-md-6 d-flex order-md-1" data-aos="zoom-in" data-aos-duration="1500">
								<div id="lrt" class="img__main align-self-end"></div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
// import $ from 'jquery';
import lottie from 'lottie-web'
import boat from '@/assets/lottie/mission_boat.json'
import train from '@/assets/lottie/mission_train.json'
import mrt from '@/assets/lottie/mission_mrt.json'
import lrt from '@/assets/lottie/mission_lrt.json'
export default {
	data() {
		return {
			TrafficArr: [boat,train,mrt,lrt],
			TrafficBox: ['boat','train','mrt','lrt'],
		}
	},
	mounted() {
		this.Ainit()
	},
	methods: {
		Ainit() {
			for(var i=0;i<this.TrafficArr.length;i+=1){
				this.anim = lottie.loadAnimation({
					container: document.getElementById(this.TrafficBox[i]), // 包含动画的dom元素
					renderer: 'svg', // 渲染出来的是什么格式
					loop: true, // 循环播放
					autoplay: true, // 自动播放
					animationData: this.TrafficArr[i], // 动画json的路径
				}); 
				console.log(this.anim);
			}
			
			setTimeout(() => {
				this.anim.play()
			}, 500)
		},
	},
}
</script>